import React, { useEffect, useState } from 'react';
import Spin, { Button, Input, Modal, notification, Select } from 'antd';
import PropTypes from 'prop-types';
import { MailOutlined } from '@ant-design/icons';
import { addIndex, map, forEach, repeat, append, update, adjust, assoc, compose, reduce, pluck, uniq, reject, prop, isNil, isEmpty, length, filter } from 'ramda';
import Title from '../../../components/Title';
import ActionButton from '../components/ActionButton';
import { emailIsValid } from '../../../lib/validation';
import InviteUserToTeamMutation from '../../../_graphql/mutations/InviteUserToTeamMutation';
import { successNotification } from '../../../utils/messageMutation';
import { TEAM_ROLES } from '../../../lib/rights';

const mapIndexed = addIndex(map);
const forEachIndexed = addIndex(forEach);
const defaultInvitation = {
  email: '', loading: false, role: TEAM_ROLES[1].value
};

const useInviteUsers = (team, onCancel) => {
  const [invitations, setInvitations] = useState(repeat(defaultInvitation, 3));
  const [invited, setInvited] = useState(false);
  const sendInvitations = () => setInvitations(append(defaultInvitation));
  const updateInvitation = (index, email, role) => setInvitations(update(index, {
    email, loading: false, role
  }));
  const updateLoading = (index, loading) => setInvitations(adjust(index, assoc('loading', loading)));

  useEffect(() => {
    if (invited) {
      const stillLoading = compose(
        reduce((acc, l) => acc || l, false),
        pluck('loading')
      )(invitations);

      if (!stillLoading) {
        onCancel();
        setInvited(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invited, invitations]);

  const definedEmails = compose(
    uniq,
    reject(v => isNil(v) || isEmpty(v)),
    pluck('email')
  )(invitations);

  const emailsAreValid = reduce((acc, email) => acc && emailIsValid(email), true)(definedEmails);

  const onInviteUsers = () => {
    compose(
      forEachIndexed(({ email, role }) => {
        updateLoading(true);
        setInvited(true);
        InviteUserToTeamMutation({
          teamId: prop('teamId', team), teamName: prop('name', team), email, role
        }, (ok, error) => {
          updateLoading(false);
          if (ok && !error) {
            successNotification(`Une invitation a été envoyée à ${email}.`);
          } else {
            notification.error({ message: `L'invitation n'a pas pu être envoyée à ${email}.` });
          }
        });
      }),
      filter(({ email }) => emailIsValid(email))
    )(invitations);
    setInvitations(repeat(defaultInvitation, 3));
  };

  return {
    invitations,
    sendInvitations,
    updateInvitation,
    definedEmails,
    emailsAreValid,
    onInviteUsers
  };
};

const ModalInviteMembers = ({
  visible,
  onCancel,
  team
}) => {
  const {
    invitations,
    sendInvitations,
    updateInvitation,
    definedEmails,
    emailsAreValid,
    onInviteUsers
  } = useInviteUsers(team, onCancel);

  return (
    <Modal
      title="Inviter des collaborateurs"
      className="team-modal"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={640}
    >
      <p>Vous pouvez inviter un ou plusieurs collaborateurs à rejoindre votre équipe</p>

      <Title
        title={`Équipe ${prop('name', team)}`}
        icon="/icon/icon-equipe-dark.svg"
      />

      <b>E-mails</b>

      {mapIndexed(({ email, role, loading }, index) => (
        <div className="flex mb-2 items-stretch">
          <Input
            value={email}
            placeholder="E-mail collaborateur"
            onChange={e => updateInvitation(index, e.target.value, role)}
            key={index}
            prefix={loading ? <Spin spinning/> : <MailOutlined className="w-5"/>}
            className="w-4/6"
          />
          <Select
            value={role}
            onChange={role => updateInvitation(index, email, role)}
            className="w-2/6 ml-2"
          >
            {map(({ label, value }) => {
              return (
                <Select.Option
                  key={value}
                  value={value}
                >
                  {label}
                </Select.Option>
              );
            })(TEAM_ROLES)}
          </Select>
        </div>
      ))(invitations)}

      <ActionButton
        onClick={sendInvitations}
        className="text-xs"
      >
        + Ajouter un autre collaborateur
      </ActionButton>

      <div className="team-modal-buttons">
        <Button onClick={onCancel}>Annuler</Button>
        <Button
          onClick={onInviteUsers}
          type="primary"
          disabled={!emailsAreValid || length(definedEmails) === 0}
        >
          Inviter
        </Button>
      </div>
    </Modal>
  );
};

ModalInviteMembers.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired
};

export default ModalInviteMembers;
